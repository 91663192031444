import { makeStyles, Paper, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { SortableTable } from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { Config } from 'config';
import { useApiClient } from 'hooks';
import { RoundRobinStanding } from 'models';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { DelayedLinearProgress } from 'components/DelayedLinearProgress';

interface Params {
  competitionId: string;
}

const useStyles = makeStyles({
  leftMatch: {
    display: 'flex',
  },
});

export const Standings: React.FC = () => {
  const classes = useStyles();
  const { competitionId } = useParams<Params>();
  const [{ data: standings, loading, error }] = useApiClient<
    RoundRobinStanding[]
  >(`/round-robin-standings/club-table/${competitionId}`);

  const columns: SortableTableHeader[] = [
    { key: 'team', label: '' },
    { key: 'gamesPlayed', label: 'GP' },
    { key: 'won', label: 'W' },
    { key: 'draws', label: 'D' },
    { key: 'losses', label: 'L' },
    { key: 'goalsFavour', label: 'GF' },
    { key: 'goalsAgainst', label: 'GA' },
    { key: 'goalsDiff', label: 'GD' },
    { key: 'points', label: 'PTS' },
  ];

  const rows =
    standings?.map((standing) => ({
      key: standing.id.toString(),
      cells: [
        {
          key: 'team',
          display: (
            <div className={classes.leftMatch}>
              {standing.teamCompetition.team.club.imageUrl ? (
                <img
                  src={`${Config.IMAGE_CDN_URL}${standing.teamCompetition.team.club.imageUrl}`}
                  alt={`${standing.teamCompetition.team.club.name} logo`}
                  style={{ height: 22, marginLeft: 8 }}
                />
              ) : null}
              <Typography style={{ paddingLeft: 5 }} variant="subtitle2">
                {standing.teamCompetition.teamName}
              </Typography>
            </div>
          ),
        },
        { key: 'gamesPlayed', display: standing.gamesPlayed },
        { key: 'won', display: standing.wins },
        { key: 'draws', display: standing.draws },
        { key: 'losses', display: standing.losses },
        { key: 'goalsFavour', display: standing.goalsFavour },
        { key: 'goalsAgainst', display: standing.goalsAgainst },
        {
          key: 'goalsDiff',
          display: standing.goalsFavour - standing.goalsAgainst,
        },
        {
          key: 'points',
          display: (
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
              {standing.points - standing.pointsDeducted}
              {standing.pointsDeducted > 0 && (
                <>
                  <Tooltip
                    title={`Points deducted: ${standing.pointsDeducted}`}
                    placement="top"
                  >
                    <span
                      style={{ marginLeft: 5, fontSize: '1.2em', color: 'red' }}
                    >
                      *
                    </span>
                  </Tooltip>
                </>
              )}
            </div>
          ),
        },
      ],
    })) ?? [];

  return (
    <React.Fragment>
      <Paper>
        <DelayedLinearProgress loading={loading} />

        {error && (
          <Typography color="error" component="span">
            {error}
          </Typography>
        )}

        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Standings</Typography>
        </Toolbar>

        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No Standings available yet."
          disablePagination
          disableSorting
        />
      </Paper>
    </React.Fragment>
  );
};
